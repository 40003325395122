import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { isBrowser } from 'src/utils/device';
import type { Auth0TenantName } from 'src/utils/tenants';

interface DatadogInitProps {
  site: string;
  tenant: Auth0TenantName;
  environment: string;
  version: string;
  language: string;
  locale?: string;
}

export const isDataDogRUMEnabled = () =>
  isBrowser() &&
  datadogRum &&
  Number(datadogRum.getInitConfiguration()?.sessionSampleRate) > 0;

export const initializeDatadog = ({
  environment,
  version,
  ...context
}: DatadogInitProps) => {
  try {
    if (isDataDogRUMEnabled()) {
      return;
    }

    datadogRum.init({
      applicationId: '95c6820f-c95d-424a-8f54-402165c2634c',
      clientToken: 'pub623c02f37787620209509f1dc7c70fa3',
      site: 'datadoghq.com',
      service: 'online-identity-oauth-spa',
      env: environment,
      version,
      sessionSampleRate: 10,
      sessionReplaySampleRate: 2,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogLogs.init({
      clientToken: 'pub623c02f37787620209509f1dc7c70fa3',
      site: 'datadoghq.com',
      service: 'online-identity-oauth-spa',
      env: environment,
      version,
      sessionSampleRate: 10,
      forwardErrorsToLogs: true,
    });

    datadogRum.setGlobalContext(context);
    datadogLogs.setGlobalContext(context);

    try {
      const cookies = document.cookie.split(';');
      const a0Cookies = cookies.filter((c) => c.includes('a0.spajs'));

      addMetadata('txs_cookie_count', String(a0Cookies.length));

      const scopesInLocalStorage = localStorage.getItem('oi__scopes');
      if (scopesInLocalStorage) {
        addMetadata('scopes_ls_value', scopesInLocalStorage);
      }
    } catch {
      // eslint-disable-next-line no-console
      console.warn('Could not track metrics for OI.');
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('An error occurred setting up error reporting', error);
  }
};

export const track = (e: Error | string | any): void => {
  if (isDataDogRUMEnabled()) {
    if (e instanceof Error) {
      datadogRum.addError(e);
    } else {
      datadogRum.addError(new Error(e));
    }
  }
};

export const addMetadata = (key: string, value: string): void => {
  if (isDataDogRUMEnabled()) {
    datadogRum.setGlobalContextProperty(key, value);
  }
};

export default datadogRum;
